import React, { Component } from 'react';

import Welcome from './Welcome';
import BasicInfo from './BasicInfo';
import Address from './Address';
import Contact from './Contact';
import ProjectOverview from './ProjectOverview';
import InputTable from './InputTable';
import EditableCell from './components/EditableCell';
import InputFloorData from './InputFloorData';
import InputData from './InputData';
import CAD from './CAD';
import Notes from './Notes';
import ThankYou from './ThankYou';
import Review from './Review';
import Loading from './Loading';

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.handleUnload = this.handleUnload.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleUnload);
    }
    
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }
    
    handleUnload(e) {
        if (this.state.step < 8) {
            var message = "\o/";
    
            (e || window.event).returnValue = message; //Gecko + IE
            return message;
        }
    }

    state = {
        step: 1,
        layoutStepIndex: 0,
        projectTitle: '',
        gc: '',
        architect: '',
        address: '',
        // streetAddress: '',
        // city: '',
        // state: '',
        // zipcode: '',
        name: '',
        company: '',
        email: '',
        phone: '',
        numberOfFloors: '',
        numberOfUniqueFloors: 1,
        approxFloorSize: '',
        rowData: [{ layoutIndex: 0, layoutName: '', howManyFloors: '', floorNumbers: '', lfFraming: '', lfFinish: '', includeFinLay: 'No', doorCount: '', pointCount: '', lfDuctwork: '', lfPiping: '', penetrationCount: '', enhancedLayout: '', scheduleNotes: '' }],
        drawing: '',
        cadHelp: '',
        scheduleNotes: '',
        extraNotes: '',
    }
    
    
    onAddRowClick = () => {
        this.setState({ rowData:
            this.state.rowData.concat({ layoutIndex: 1, layoutName: '', howManyFloors: '', floorNumbers: '', pointCount: '', lfDuctwork: '', lfPiping: '', penetrationCount: '', textCount: '', layoutNotes: '' })
        })
    }

    onAddMultRow = (num) => {
        let tempArr = this.state.rowData

        // returning, same number
        if (tempArr.length == num) {
            // console.log("hello")
        }
        
        // returning, lower number
        if (tempArr.length > num) {
            // console.log("lower num selected")
            let difference = tempArr.length - num
            // console.log('difference', difference)

            for (let i=0; i < difference; i++) {
                // tempArr.slice(0, -1)
                tempArr.pop()
            }
            // console.log('after', tempArr)
            this.setState({ rowData: tempArr })
        }

        // returning, higher number
        if (tempArr.length < num) {
            // console.log("higher num selected")
            let difference = num - tempArr.length
            // console.log('difference', difference)
            for (let i=0; i < difference; i++) {
                tempArr.push({ layoutIndex: i+1, layoutName: '', howManyFloors: '', floorNumbers: '', pointCount: '', lfDuctwork: '', lfPiping: '', penetrationCount: '', textCount: '', layoutNotes: '' })
            }
            // console.log('after', tempArr)
            this.setState({ rowData: tempArr })
        }

        // first time completing step
        if (tempArr.length == 1) {
            for (let i=0; i < num-1; i++) {
                tempArr.push({ layoutIndex: i+1, layoutName: '', howManyFloors: '', floorNumbers: '', pointCount: '', lfDuctwork: '', lfPiping: '', penetrationCount: '', textCount: '', layoutNotes: '' })
            }
            // console.log('after', num, tempArr)
            this.setState({ rowData: tempArr })
            // console.log('state', this.state.rowData)
            // console.log('first time')
        }
    }

    onRemoveRowClick = () => {
        this.setState({ rowData:
            this.state.rowData.slice(0, -1)
        })
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
        console.log(this.state)
    }

    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }

    handleFileChange = (key, file) => {
        this.setState({ [key]: file });
    }

    updateRowData = (index, key, value) => {
        let temp_state = [...this.state.rowData]
        let temp_element = { ...temp_state[index]}
        temp_element[key] = value
        temp_state[index] = temp_element;
        this.setState({ rowData: temp_state })
    }

    decrementLayoutStepIndex = () => {
        const { layoutStepIndex } = this.state;
        this.setState({ layoutStepIndex: layoutStepIndex - 1 });
    }

    incrementLayoutStepIndex = () => {
        const { layoutStepIndex } = this.state;
        this.setState({ layoutStepIndex: layoutStepIndex + 1 });
    }

    updateMyData = (rowIndex, columnId, value) => {
        this.setState({ rowData: 
            (old) => old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex],
                    [columnId]: value,
                  };
                }
                return row;
            })
        })
    };

    updateTable = (value, rowIndex, columnId) => {
        let temp_state = [...this.state.rowData]
        let temp_element = { ...temp_state[rowIndex]}
        temp_element[columnId] = value
        temp_state[rowIndex] = temp_element;
        this.setState({ rowData: temp_state })
    }

    removeFloorAndStepBack = (currentIndex) => {
        // let tempArr = this.state.rowData;
        // tempArr.splice(currentIndex + 1, 1);
        // console.log(this.state.rowData)

        // this.setState({ rowData: tempArr }, () => {
        //     console.log(this.state.rowData)
        // })

        this.setState((prevState) => ({
            rowData: [...prevState.rowData.slice(0,currentIndex), ...prevState.rowData.slice(currentIndex+1)]
        }))

        const { numberOfUniqueFloors } = this.state;

        this.setState({ numberOfUniqueFloors: numberOfUniqueFloors - 1 }, () => {
            // if (this.state.layoutStepIndex == (this.state.numberOfUniqueFloors - 1)) {
            //     console.log(this.state.layoutStepIndex, this.state.numberOfUniqueFloors - 1)
            //     this.prevStep();
            // } else {
            //     this.decrementLayoutStepIndex()
            //     // setCurrentIndex(currentIndex + 1)
            // }
            // this.decrementLayoutStepIndex()
            console.log(this.state);
        });

    }

    addFloorAndStepForward = () => {
        
        let tempArr = this.state.rowData;
        tempArr.push({ layoutIndex: tempArr.length, layoutName: '', howManyFloors: '', floorNumbers: '', lfFraming: '', lfFinish: '', includeFinLay: 'No', doorCount: '', pointCount: '', lfDuctwork: '', lfPiping: '', penetrationCount: '', enhancedLayout: '', scheduleNotes: '' })
        
        // console.log(tempArr)
        // const { layoutStepIndex } = this.state;
        // this.setState({ layoutStepIndex: layoutStepIndex + 1 });

        const { numberOfUniqueFloors } = this.state;
        // this.setState({ numberOfUniqueFloors: numberOfUniqueFloors + 1 })
        this.setState({ numberOfUniqueFloors: numberOfUniqueFloors + 1 }, () => {
            if (this.state.layoutStepIndex == (this.state.numberOfUniqueFloors - 1)) {
                console.log(this.state.layoutStepIndex, this.state.numberOfUniqueFloors - 1)
                this.nextStep();
            } else {
                this.incrementLayoutStepIndex()
                // setCurrentIndex(currentIndex + 1)
            }
        });
        
        // console.log(this.state)

        // this.incrementLayoutStepIndex()
    }

    render() {
        const { step } = this.state;
        const { projectTitle, gc, architect, address, name, company, email, phone, numberOfFloors, numberOfUniqueFloors, dataTable, drawing, cadHelp, scheduleNotes, extraNotes, columns, rowData, layoutStepIndex, approxFloorSize } = this.state;
        const values = { projectTitle, gc, architect, address, name, company, email, phone, numberOfFloors, numberOfUniqueFloors, dataTable, drawing, cadHelp, scheduleNotes, extraNotes, columns, rowData, layoutStepIndex, approxFloorSize }

        switch(step) {
            case 1:
                return (
                    <Welcome
                        nextStep={ this.nextStep }
                    />
                )
            case 2:
                return (
                    <Contact
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        handleChange={ this.handleChange }
                        values={ values }
                    />
                )
            case 3:
                return (
                    <BasicInfo
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        handleChange={ this.handleChange }
                        values={ values }
                    />
                )
            // case 4:
            //     return (
            //         <Address
            //             prevStep={ this.prevStep }
            //             nextStep={ this.nextStep }
            //             handleChange={ this.handleChange }
            //             values={ values }
            //         />
            //     )
            // case 5:
            //     return (
            //         <ProjectOverview
            //             prevStep={ this.prevStep }
            //             nextStep={ this.nextStep }
            //             handleChange={ this.handleChange }
            //             values={ values }
            //             addRowFunction={this.onAddMultRow}
            //         />
            //     )
            case 4:
                return (
                    <InputData
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        updateRowData={this.updateRowData}
                        values={ values }
                        decrementLayoutStepIndex={this.decrementLayoutStepIndex}
                        incrementLayoutStepIndex={this.incrementLayoutStepIndex}
                        removeFloorAndStepBack={this.removeFloorAndStepBack}
                        addFloorAndStepForward={this.addFloorAndStepForward}
                    />
                    // <InputTable
                    //     prevStep={ this.prevStep }
                    //     nextStep={ this.nextStep }
                    //     columns={ values.columns }
                    //     data={ values.rowData }
                    //     addRowFunction={this.onAddRowClick}
                    //     removeRowFunction={this.onRemoveRowClick}
                    //     updateTable={this.updateTable}
                    //     updateMyData={this.updateMyData}
                    // />
                )
            case 5:
                return (
                    <CAD
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        handleChange={ this.handleChange }
                        values={ values }
                        handleFileChange={this.handleFileChange}
                    />
                )
            case 6:
                return (
                    <Notes
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        handleChange={ this.handleChange }
                        values={ values }
                    />
                )
            case 7:
                return (
                    <Review
                        prevStep={ this.prevStep }
                        nextStep={ this.nextStep }
                        values={ values }
                    />
                )
            case 8:
                return (
                    <Loading
                        nextStep={ this.nextStep }
                        values={ values }
                    />
                )
            case 9:
                return (
                    <ThankYou
                        values={ values }
                    />
                )
            default:
                return (
                    <Welcome />
                )
        }
    }
}