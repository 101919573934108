import React from 'react';
import { useTable } from 'react-table';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomDescription from './components/CustomDescription';
import NavButtons from './components/NavButtons';

const InputTable = ({ prevStep, nextStep, columns, data, addRowFunction, removeRowFunction, updateMyData, updateTable }) => {
    const classes = useStyles();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // state,
    } = useTable({
        columns,
        data,
        updateMyData,
        updateTable
    });

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Floor plan descriptions"}/>
            <CustomDescription content={"Please input data for each unique floor plan. For more information visit our guide."} />
            <div className={classes.tableContainer}>
                <table className={classes.table} {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr className={classes.tableColumn} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className={classes.tableHeader} {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="tableBody" {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td className={classes.tableCell} {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.removeRowBtn}
                    variant="contained"
                    size="small"
                    onClick={removeRowFunction}
                >
                    <RemoveIcon />
                </Button>
                <Button
                    className={classes.addRowBtn}
                    variant="contained"
                    size="small"
                    onClick={addRowFunction}
                >
                    <AddIcon />
                </Button>
            </div>
            <NavButtons prevStep={prevStep} nextStep={nextStep} />
        </div>
    );
};


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    tableContainer: {
        // margin: 'auto',
        // justifyContent: 'center',
        // width: '50%',
        // textAlign: 'center'
        width: '100%',
        margin: 'auto',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    table: {
        backgroundColor: 'rgba(246, 134, 42, 1)',
        margin: '0 auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        borderRadius: '10px 10px 10px 0px',
        padding: '8px',
    // make columns equal
        // tableLayout: 'fixed',
        // width: '100%'
    },
    tableColumn: {
        fontFamily: "Roboto",
        fontSize: '12px'
    },
    tableHeader: {
        color: 'white',
        flexGrow: 1,
        flex: 1,
        flexBasis: 0
    },
    tableCell: {
        height: 20
    },
    addRowBtn: {
        borderRadius: '0px 0px 10px 0px',
        fontSize: 'small'
    },
    removeRowBtn: {
        borderRadius: '0px 0px 0px 10px',
    },
    buttonContainer: {
        display: 'flex',
    }
}))

export default InputTable
