import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';

const ThankYou = ({ values }) => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Thank you for the information!"}/>
            <Typography className={classes.description} variant="h2">
                A copy of this information has been sent to you at <span className={classes.emailLink}>{values.email}</span>
            </Typography>
            <Typography className={classes.description} variant="h2">
                We will look over the information and prepare an estimate for you as promptly as we can. If you have any questions, please contact us at <a href="mailto:sales@dustyrobotics.com" className={classes.emailLink}>sales@dustyrobotics.com</a>
            </Typography>
            <Typography className={classes.description} variant="h2">
                We are looking forward to making your project successful!
            </Typography>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    description: {
        color: 'white',
        fontSize: '18px',
        marginTop: '20px',
        marginBottom: '20px',
        fontWeight: '400'
    },
    emailLink: {
        color: 'orange'
    }
}))

export default ThankYou
