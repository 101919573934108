import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomDescription from './components/CustomDescription';
import CustomTextField from './components/CustomTextField';

const ProjectOverview = ({ prevStep, nextStep, handleChange, values, addRowFunction }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (values.numberOfFloors != '' && values.numberOfUniqueFloors != '' && values.approxFloorSize != '' && values.numberOfFloors >= 0 && values.numberOfUniqueFloors >= 0 && values.approxFloorSize >= 0) {
            setDisabled(false)
        }
        if (values.numberOfFloors == '' || values.numberOfUniqueFloors == '' || values.approxFloorSize == '' || values.numberOfFloors <= 0 || values.numberOfUniqueFloors <= 0 || values.approxFloorSize <= 0) {
            setDisabled(true)
        }
    }, [values])

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    const Continue = e => {
        e.preventDefault();
        addRowFunction(parseInt(values.numberOfUniqueFloors))
        nextStep();
    }

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"How many floors?"}/>
            <CustomDescription content={"Let us know the number of floors we should print and how many unique layouts there are"} />
            <form>
                <CustomTextField
                    className={classes.inputField}
                    type="number"
                    label="# of floors"
                    onChange={handleChange('numberOfFloors')}
                    defaultValue={values.numberOfFloors}
                />
                <CustomTextField
                    className={classes.inputField}
                    type="number"
                    label="# of unique floor plans"
                    onChange={handleChange('numberOfUniqueFloors')}
                    defaultValue={values.numberOfUniqueFloors}
                />
                <CustomTextField
                    className={classes.inputField}
                    type="number"
                    label="Average floor size (sq. ft)"
                    onChange={handleChange('approxFloorSize')}
                    defaultValue={values.approxFloorSize}
                />
                <br/>
                <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Previous }>
                    Back
                </SpecialButton>
                <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Continue } disabled={disabled}>
                    Next
                </SpecialButton>
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    navBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        marginBottom: '8vh',
        '@media (max-width:450px)': {
            marginTop: '10vw',
        },
    }
}))

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
      '&.Mui-disabled': {
          color: 'gray',
          borderColor: 'gray',
          backgroundColor: 'rgba(255, 255, 255, 0.15)'
      }
    },
}))(Button);

export default ProjectOverview
