import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const CustomHeader = ({ content }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.description} variant="h2">
            {content}
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    description: {
        color: 'white',
        fontSize: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        '@media (max-width:550px)': {
            fontSize: '5vw',
        },
        '@media (max-width:350px)': {
            fontSize: '18px',
        },
    },
}))

export default CustomHeader
