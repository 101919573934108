import React from 'react';
import { Typography } from '@material-ui/core'

const Footer = () => {
    return (
        <div style={{
            // backgroundColor: 'red',
            maxWidth: '550px',
            width: '70%',
            margin: 'auto',
            padding: '5px',
            borderRadius: '5px',
            paddingBottom: '30px'
        }}>
            <Typography style={{
                color: 'white',
                marginTop: 'auto',
                fontSize: '13px'
            }}>
                If you have any questions, please contact <a href="mailto:sales@dustyrobotics.com" target="_blank" style={{color: 'orange'}}>sales@dustyrobotics.com</a>
            </Typography>
        </div>
    )
}

export default Footer
