import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, TextField, Radio, RadioGroup, FormControl, FormControlLabel, Link } from '@material-ui/core';

import LogoStamp from './components/LogoStamp';
import CustomTextField from './components/CustomTextField';
import CustomHeader from './components/CustomHeader';
import NavButtons from './components/NavButtons';
import ProgressBar from './components/ProgressBar';
import LinkIcon from '@material-ui/icons/Link';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { Widget } from "@uploadcare/react-widget";
import Footer from './components/Footer';

const CAD = ({ prevStep, nextStep, handleChange, handleFileChange, values }) => {
    const classes = useStyles();
    const [cadHelp, setCadHelp] = useState();
    const [disabled, setDisabled] = useState(true);
    const [isUCfile, setIsUCfile] = useState(true);

    useEffect(() => {
        if (values.drawing !== '' && values.cadHelp !== '') {
            setDisabled(false)
        }
        if (values.drawing === '' || values.cadHelp === '') {
            setDisabled(true)
        }
    }, [values])

    const handleUploadcare = (res) => {
        // console.log(res)
        handleFileChange('drawing', res.cdnUrl)
    }

    const handleSelect = (event) => {
        setCadHelp(event.target.value);
    };
    
    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Files & attachments"}/>
            <form id="cadForm">
                <div className={classes.formContainer}>
                    <Typography className={classes.questionText}>
                        We will need at least architectural PDFs to estimate this project. If you also have Revit or CAD files, please provide them as well.
                    </Typography>
                    {/* <input className={classes.drawingInput} type="file" name="drawing" onChange={changeHandler} /> */}
                    <div className={classes.spacer1}></div>


                    { isUCfile ? (
                        <>
                            <Widget className={classes.uploadcareBtn} multiple='true' onChange={res => handleUploadcare(res)} publicKey="d0c3811ba6eef291a7b6" clearable />
                            <p className={classes.orText}>or</p>
                            <SpecialButton size="small" className={classes.urlButton} onClick={() => setIsUCfile(!isUCfile)}><LinkIcon />URL</SpecialButton>
                        </>
                    ) : (
                        <>
                            <SpecialTextField
                                InputLabelProps={{ shrink: true }}
                                className={classes.textField}
                                type="text"
                                variant="outlined"
                                label="URL link to files"
                                onChange={handleChange('drawing')}
                                defaultValue={values.drawing}
                            />
                            <p className={classes.orText}>or</p>
                            <SpecialButton size="small" className={classes.urlButton} onClick={() => setIsUCfile(!isUCfile)}><AttachFileIcon />Attach files</SpecialButton>
                        </>
                    )}

                    <div className={classes.spacer2}></div>

                    <Typography className={classes.questionText}>
                        Do you need assistance with preparing CAD files for printing, at an additional cost? More information can be found <a className={classes.linkText} href="http://dustyrobotics.com/docs" target="_blank">here</a>.
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup defaultValue={values.cadHelp} aria-label="cadHelp" name="cadHelp1" value={cadHelp} onChange={handleSelect}>
                            <FormControlLabel value="No" control={<OrangeRadio />} label="No" onChange={handleChange('cadHelp')} />
                            <FormControlLabel value="Yes" control={<OrangeRadio />} label="Yes" onChange={handleChange('cadHelp')} />
                        </RadioGroup>
                    </FormControl>
                </div>
                {/* <div className={classes.detailContainer}>
                    <Typography className={classes.detailText}>
                        More information about preparing CAD files for Dusty layout can be found <a className={classes.linkText} href="http://dustyrobotics.com/docs" target="_blank">here.</a>
                    </Typography>
                </div> */}
                <NavButtons prevStep={prevStep} nextStep={nextStep} disabled={disabled}/>
                <ProgressBar step={4} completed={67} />
            </form>
            <Footer />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    formContainer: {
        backgroundColor: 'rgba(225, 225, 225, 0.8)',
        borderRadius: '10px',
        width: '50vw',
        margin: 'auto',
        marginTop: '15px',
        padding: '4vw',
        textAlign: 'left',
        '@media (max-width:750px)': {
            width: 'auto',
        },
        '@media (max-width:500px)': {
            padding: '25px',
        },
    },
    detailContainer: {
        width: '50vw',
        margin: 'auto',
        marginTop: '15px',
        textAlign: 'left',
        '@media (max-width:750px)': {
            width: 'auto',
        },
        '@media (max-width:500px)': {
            padding: '5px',
            marginTop: '5px',
        },
    },
    spacer1: {
        height: '5px'
    },
    spacer2: {
        height: '20px'
    },
    uploadcareBtn: {
        backgroundColor: 'red',
        marginTop: '30px'
    },
    urlButton: {
        backgroundColor: 'gray',
        color: 'white'
    },
    detailText: {
        fontSize: '14px',
        color: 'white',
        marginTop: '10px',
        textAlign: 'left'
    },
    linkText: {
        // color: 'orange'
        color: '#096fdb',
    },
    textField: {
        backgroundColor: 'rgba(180, 180, 180, 0.8)',
        borderRadius: '5px',
        marginTop: '1vw',
        '@media (max-width:550px)': {
            margin: '3vw',
        },
        '@media (max-width:350px)': {
            margin: '3vw',
        },
    },
    drawingInput: {
        marginBottom: '20px',
        marginTop: '5px'
    },
    drawingLink: {
        color: 'blue'
    },
    orText: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    removeDrawing: {
        color: 'white',
        backgroundColor: 'black',
        borderRadius: '5px',
        border: 'none',
        padding: '4px'
    }
}))

const OrangeRadio = withStyles({
    root: {
      color: 'black',
      '&$checked': {
        color: 'rgba(246, 134, 42, 1)',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(150, 150, 150, 1)'
      },
    },
}))(Button);

export default CAD
