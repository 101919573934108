const ProgressBar = (props) => {
    const { step, completed } = props;
  
    const mainContainerStyles = {
        paddingBottom: '40px',
    }

    const barStyles = {
      height: 7,
      width: '60%',
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      borderRadius: 100,
      margin: 'auto',
      maxWidth: '170px',
      padding: 2
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: "rgba(246, 134, 42, 1)",
      borderRadius: 'inherit',
      textAlign: 'right',
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }

    const textStyles = {
        color: 'white',
        fontSize: '12px',
        marginTop: '6px',
        // marginBottom: '50px',
    }
  
    return (
        <div style={mainContainerStyles}>
            <div style={barStyles}>
                <div style={fillerStyles}>
                {/* <span style={labelStyles}>{`${completed}%`}</span> */}
                </div>
            </div>
            <p style={textStyles}>
                Step {step} of 6
            </p>
        </div>
    );
};
  
export default ProgressBar;