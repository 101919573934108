import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomDescription from './components/CustomDescription';

const Welcome = ({ nextStep }) => {
    const classes = useStyles();

    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Welcome to Dusty Robotics"}/>
            <Typography className={classes.subheader} variant="h2">
                Project Bid Form
            </Typography>
            <CustomDescription content={"In order for us to provide an accurate and timely quote for your project, please fill out this online form with your information"} />
            <StartButton className={classes.startBtn} variant="outlined" onClick={ Continue }>
                Get started
            </StartButton>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    subheader: {
        color: 'white',
        fontSize: '50px',
        '@media (max-width:550px)': {
            fontSize: '8vw',
        },
        '@media (max-width:350px)': {
            fontSize: '10vw',
        },
    },
    startBtn: {
        color: 'white',
        borderColor: 'white',
        marginBottom: '8vh'
    }
}))

const StartButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.9)'
      },
    },
}))(Button);

export default Welcome
