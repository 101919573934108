import React from 'react';
import './App.css';
import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Form from './pages/Form';

function App() {
  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
