import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dustyLogo from '../../images/dusty-logo.png';

const LogoStamp = () => {
    const classes = useStyles();

    return (
        <img className={classes.logo} src={dustyLogo} alt="dusty-logo"/>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    logo: {
        height: '100px',
        marginTop: '15vw'
    },
}))

export default LogoStamp
