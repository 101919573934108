import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import NavButtons from './components/NavButtons';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';

const Notes = ({ prevStep, nextStep, handleChange, values }) => {
    const classes = useStyles();
    // const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        // if (values.scheduleNotes !== '') {
        //     setDisabled(false)
        // }
        // if (values.scheduleNotes === '') {
        //     setDisabled(true)
        // }
    }, [values])

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Anything else we should know?"}/>
            <form className={classes.form}>
                <Typography className={classes.questionText}>
                    Please state any additional details our team should be aware of for this project:
                </Typography>
                <SpecialTextField
                    className={classes.inputField}
                    multiline
                    rows={6}
                    variant="outlined"
                    onChange={handleChange('extraNotes')}
                    defaultValue={values.extraNotes}
                />
                <NavButtons prevStep={prevStep} nextStep={nextStep}/>
                <ProgressBar step={5} completed={83} />
            </form>
            <Footer />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    form: {
        maxWidth: '900px',
        margin: 'auto'
    },
    questionText: {
        color: 'white',
        textAlign: 'left',
        marginTop: '20px',
        marginBottom: '5px'
    },
    inputField: {
        color: 'white',
        borderColor: 'white',
        backgroundColor: 'rgba(225, 225, 225, 0.8)',
        borderRadius: '5px',
        width: '100%',
        flex: 1,
    },
}))

const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'dark gray',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

export default Notes
