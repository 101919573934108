import React from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomTextField from './components/CustomTextField';
import LogoStamp from './components/LogoStamp';
import LayoutNumber from './components/LayoutNumber';


class InputFloorData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: this.props.values.layoutStepIndex,
            disabled: true
        }
        this.Previous = this.Previous.bind(this);
        this.Continue = this.Continue.bind(this);
    };

    Previous = e => {
        e.preventDefault();

        // if this is the first layout, go back to prev step
        if (this.props.values.layoutStepIndex == 0) {
            this.props.prevStep();
        } else {
            this.props.decrementLayoutStepIndex()
            this.setState({ currentIndex: this.state.currentIndex - 1})
        }
    }
    Continue = e => {
        e.preventDefault();

        // if this is the last layout, continue to next step
        if (this.props.values.layoutStepIndex == (parseInt(this.props.values.numberOfUniqueFloors) - 1)) {
            this.props.nextStep();
        } else {
            this.props.incrementLayoutStepIndex()
            this.setState({ currentIndex: this.state.currentIndex + 1})
        }
    }
    
    render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.mainContainer}>
                    <LogoStamp />
                    <Typography className={classes.header} variant="h2">
                        Floor plan #{this.state.currentIndex + 1}
                    </Typography>
                    <Typography className={classes.description} variant="h2">
                        Please input data for floor plan {this.state.currentIndex + 1} out of {this.props.values.numberOfUniqueFloors}
                    </Typography>
                    <div key={this.state.currentIndex}>
                        <CustomTextField
                            className={classes.inputField}
                            label="Layout Name"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'layoutName', e.target.value)}
                            defaultValue={this.props.values.rowData[this.state.currentIndex].layoutName}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="# of floors"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'howManyFloors', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].howManyFloors}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="Floor numbers"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'floorNumbers', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].floorNumbers}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="# of hanger points"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'pointCount', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].pointCount}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="Ductwork (LF)"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'lfDuctwork', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].lfDuctwork}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="Piping (LF)"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'lfPiping', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].lfPiping}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="# of penetrations"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'penetrationCount', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].penetrationCount}
                        />
                        <CustomTextField
                            className={classes.inputField}
                            label="# of additional labels"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'textCount', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].textCount}
                        />
                        <SpecialTextField
                            InputLabelProps={{ shrink: true }}
                            className={classes.longInputField}
                            label="Layout Notes"
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={(e) => this.props.updateRowData(this.props.values.layoutStepIndex, 'layoutNotes', e.target.value)}
                            defaultValue={this.props.values.rowData[this.props.values.layoutStepIndex].layoutNotes}
                        />
                    </div>
                    <br />
                    <SpecialButton className={classes.navBtn} variant="outlined" onClick={ this.Previous }>
                        Back
                    </SpecialButton>
                    <SpecialButton className={classes.navBtn} variant="outlined" onClick={ this.Continue } disabled={this.state.disabled}>
                        Next
                    </SpecialButton>
                </div>
                <LayoutNumber layoutNumber={this.state.currentIndex + 1}/>
            </>
        );
    }
}


const useStyles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    header: {
        color: 'white',
        fontFamily: 'Roboto',
        marginBottom: '2vh',
        '@media (max-width:550px)': {
            fontSize: '12vw',
        },
        '@media (max-width:350px)': {
            fontSize: '15vw',
        },
    },
    description: {
        color: 'white',
        fontSize: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        '@media (max-width:550px)': {
            fontSize: '5vw',
        },
        '@media (max-width:350px)': {
            fontSize: '18px',
        },
    },
    navBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        marginBottom: '8vh',
        '@media (max-width:450px)': {
            marginTop: '10vw',
        },
    },
    longInputField: {
        color: 'white',
        borderColor: 'white',
        // backgroundColor: 'white',
        backgroundColor: 'rgba(225, 225, 225, 0.8)',
        borderRadius: '5px',
        width: '50%',
        flex: 1,
        margin: '1vw',
        '@media (max-width:550px)': {
            width: '75%',
            marginTop: '3vw',
        },
        // maxWidth: '800px'
        // margin: '10px',
        // backgroundColor: 'white'
    },
})

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
      '&.Mui-disabled': {
        color: 'gray',
        borderColor: 'gray',
        backgroundColor: 'rgba(255, 255, 255, 0.15)'
      }
    },
}))(Button);

const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);


export default withStyles(useStyles, SpecialButton, SpecialTextField)(InputFloorData)