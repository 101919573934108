import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Radio, RadioGroup, FormControl, FormControlLabel, } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTextField from './components/CustomTextField';
import LogoStamp from './components/LogoStamp';
import LayoutNumber from './components/LayoutNumber';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';

const InputTable = ({ prevStep, nextStep, values, updateRowData, incrementLayoutStepIndex, decrementLayoutStepIndex, removeFloorAndStepBack, addFloorAndStepForward }) => {
    const classes = useStyles();
    const [currentIndex, setCurrentIndex] = useState(values.layoutStepIndex)
    const [disabled, setDisabled] = useState(true)
    const [addFloorDisabled, setAddFloorDisabled] = useState(true)
    const [includeFinLay, setIncludeFinLay] = useState();

    const Previous = e => {
        e.preventDefault();

        // if this is the first layout, go back to prev step
        if (values.layoutStepIndex == 0) {
            prevStep();
        } else {
            decrementLayoutStepIndex()
            setCurrentIndex(currentIndex - 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }
    const Continue = e => {
        e.preventDefault();

        // if this is the last layout, continue to next step
        // if (values.layoutStepIndex == (parseInt(values.numberOfUniqueFloors) - 1)) {
        if (values.layoutStepIndex == (values.numberOfUniqueFloors - 1)) {
            // console.log(values.layoutStepIndex, values.numberOfUniqueFloors - 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
            nextStep();
        } else {
            incrementLayoutStepIndex()
            setCurrentIndex(currentIndex + 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }

    const handleSelect = (event) => {
        setIncludeFinLay(event.target.value);
    };

    const handleAddFloor = (e) => {
        addFloorAndStepForward()
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        setTimeout(() => setCurrentIndex(currentIndex + 1), 500)
        // setCurrentIndex(currentIndex + 1)
        // setTimeout(() => Continue(e), 5000);
    }

    const handleRemoveFloor = (e) => {
        decrementLayoutStepIndex()
        setCurrentIndex(currentIndex - 1)
        // removeFloorAndStepBack(currentIndex)
        setTimeout(() => removeFloorAndStepBack(currentIndex), 500)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        // setCurrentIndex(currentIndex - 1)
        // removeFloorAndStepBack(currentIndex)
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        // })
        // setTimeout(() => setCurrentIndex(currentIndex - 1), 3000)
    }

    useEffect(() => {     
        
        if (values.rowData[values.layoutStepIndex].layoutName !== '' && parseInt(values.rowData[values.layoutStepIndex].howManyFloors) > 0) {
            setDisabled(false)
            setAddFloorDisabled(false)
            
            if (values.rowData[values.layoutStepIndex].lfFraming == '' && values.rowData[values.layoutStepIndex].lfFinish == '' && values.rowData[values.layoutStepIndex].doorCount == '' && values.rowData[values.layoutStepIndex].includeFinLay == '' && values.rowData[values.layoutStepIndex].pointCount == '' && values.rowData[values.layoutStepIndex].lfDuctPipe == '' && values.rowData[values.layoutStepIndex].enhancedLayout == '' && values.rowData[values.layoutStepIndex].scheduleNotes == '') {
                setDisabled(true)
                setAddFloorDisabled(true)
            }
        }
        
        if (values.rowData[values.layoutStepIndex].layoutName == '' || parseInt(values.rowData[values.layoutStepIndex].howManyFloors) <= 0) {
            setDisabled(true)
            setAddFloorDisabled(true)
        }
        if (currentIndex != (values.numberOfUniqueFloors - 1)) {
            setAddFloorDisabled(true)
        }
    }, [values])
    
    return (
        <>
            <div className={classes.mainContainer}>
                <LogoStamp />
                <Typography className={classes.header} variant="h2">
                    What would you like us to print?
                </Typography>
                <Typography className={classes.description} variant="h2">
                    Dusty mobilizes robots to lay out each floor/area as it becomes available. We’ll need to know how much you want us to print during each mobilization so we can estimate price and schedule.
                </Typography>
                { values.layoutStepIndex == 0 && (
                    <Typography className={classes.description} variant="h2">
                        Let’s collect this information for the first floor/area. If you have more than one floor/area, which will be laid out at a later date, you will have the opportunity to provide this information by choosing 'Add new floor'.
                    </Typography>
                )}

                <Typography className={classes.layoutNum} variant="h2">
                    Unique floor plan #{currentIndex + 1} of {values.numberOfUniqueFloors}
                </Typography>

                <div key={currentIndex}>
                    <div className={classes.sectionContainer}>
                        <div className={classes.leftContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Floor description<br />(which floors have this layout):</Typography>
                                <SpecialTextField
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    // type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'layoutName', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].layoutName}
                                />
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Number of floors<br />(how many floors have this layout):</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'howManyFloors', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].howManyFloors}
                                />
                            </div>
                        </div>
                        
                    </div>

                    <Typography className={classes.tradeText} variant="h2">
                        Drywall layout
                    </Typography>
                    <div className={classes.sectionContainer}>
                        <div className={classes.leftContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Framing layout (printed as double lines), LF of wall:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'lfFraming', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].lfFraming}
                                />
                            </div>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Soffit framing/finish (printed as double lines), <br/>LF of soffits:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'lfFinish', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].lfFinish}
                                />
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Door RO dimension text, number of doors:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'doorCount', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].doorCount}
                                />
                            </div>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Include drywall finish layout (dashed lines indicating face of finish)?</Typography>
                                <div className={classes.radioContainer}>
                                    <FormControl component="fieldset">
                                        <RadioGroup defaultValue={values.rowData[values.layoutStepIndex].includeFinLay} aria-label="cadHelp" name="cadHelp1" value={values.rowData[values.layoutStepIndex].includeFinLay} onChange={handleSelect}>
                                            <FormControlLabel className={classes.radioLabel} value="No" control={<OrangeRadio />} label="No" onChange={(e) => updateRowData(values.layoutStepIndex, 'includeFinLay', 'No')} />
                                            <FormControlLabel className={classes.radioLabel} value="Yes" control={<OrangeRadio />} label="Yes" onChange={(e) => updateRowData(values.layoutStepIndex, 'includeFinLay', 'Yes')} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Typography className={classes.tradeText} variant="h2">
                        MEP layout
                    </Typography>
                    <div className={classes.sectionContainer}>
                        <div className={classes.leftContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Hanger points (crosshair with label), <br />number of points:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'pointCount', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].pointCount}
                                />
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Ductwork layout (printed as double lines), LF of ductwork:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'lfDuctwork', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].lfDuctwork}
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className={classes.sectionContainer}>
                        <div className={classes.leftContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Pipe runs (printed as center line only), <br />LF of piping:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'lfPiping', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].lfPiping}
                                />
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.fieldContainer} align="left">
                                <Typography className={classes.fieldLabel}>Wall penetrations (for ductwork), number of penetrations:</Typography>
                                <SpecialTextField
                                    onWheel={(e) => e.target.blur()}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{ min: 0 }}
                                    variant="outlined"
                                    className={classes.inputField}
                                    type="number"
                                    onChange={(e) => updateRowData(values.layoutStepIndex, 'penetrationCount', e.target.value)}
                                    defaultValue={values.rowData[values.layoutStepIndex].penetrationCount}
                                />
                            </div>
                        </div>
                        
                    </div>

                    <Typography className={classes.tradeText} variant="h2">
                        Enhanced layout
                    </Typography>
                    <div className={classes.sectionContainer}>
                        <div className={classes.longFieldContainer} align="left">
                            <Typography className={classes.fieldLabel}>If you would like us to print something that does not fall into one of the above categories, please describe and provide quantities:</Typography>
                            <LongTextField
                                InputLabelProps={{ shrink: false }}
                                className={classes.longInputField}
                                // label="Layout Notes"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={(e) => updateRowData(values.layoutStepIndex, 'enhancedLayout', e.target.value)}
                                defaultValue={values.rowData[values.layoutStepIndex].enhancedLayout}
                            />
                        </div>

                    </div>

                    <Typography className={classes.tradeText} variant="h2">
                        Schedule notes
                    </Typography>
                    <div className={classes.sectionContainer}>
                        <div className={classes.longFieldContainer} align="left">
                            <Typography className={classes.fieldLabel}>When would you like to schedule a print job? If this layout will be printed on multiple floors, please describe the timeline for those as well:</Typography>
                            <LongTextField
                                InputLabelProps={{ shrink: false }}
                                className={classes.longInputField}
                                // label="Schedule"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={(e) => updateRowData(values.layoutStepIndex, 'scheduleNotes', e.target.value)}
                                defaultValue={values.rowData[values.layoutStepIndex].scheduleNotes}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <SpecialButton className={classes.addBtn} variant="outlined" onClick={ (e) => handleRemoveFloor(e) } disabled={currentIndex == 0}>
                    Remove floor
                </SpecialButton>
                {/* <SpecialButton className={classes.addBtn} variant="outlined" onClick={ (e) => handleAddFloor(e) } disabled={currentIndex != (values.numberOfUniqueFloors - 1)}>
                    Add new floor
                </SpecialButton> */}
                <SpecialButton className={classes.addBtn} variant="outlined" onClick={ (e) => handleAddFloor(e) } disabled={addFloorDisabled}>
                    Add new floor
                </SpecialButton>
                <br />
                <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Previous }>
                    Back
                </SpecialButton>
                { currentIndex == values.numberOfUniqueFloors - 1 ? (
                    <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Continue } disabled={disabled}>
                        Finish
                    </SpecialButton>
                ) : (
                    <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Continue } disabled={disabled}>
                        Next
                    </SpecialButton>
                )}
                <ProgressBar step={3} completed={50} />
                <Footer />
            </div>
            <LayoutNumber layoutNumber={currentIndex + 1}/>
        </>
    );
};


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto',
        maxWidth: '750px'
    },
    header: {
        color: 'white',
        fontFamily: 'Roboto',
        marginBottom: '2vh',
        '@media (max-width:550px)': {
            fontSize: '12vw',
        },
        '@media (max-width:350px)': {
            fontSize: '15vw',
        },
    },
    description: {
        color: 'white',
        fontSize: '18px',
        marginTop: '20px',
        marginBottom: '20px',
        '@media (max-width:550px)': {
            fontSize: '5vw',
        },
        '@media (max-width:350px)': {
            fontSize: '18px',
        },
    },
    layoutNum: {
        color: 'white',
        backgroundColor: 'rgba(246, 134, 42, 1)',
        fontSize: '30px',
        padding: '8px',
        // width: '200px'
        borderRadius: '10px',
        marginTop: '30px',
        marginBottom: '15px',
    },
    tradeText: {
        color: 'white',
        // backgroundColor: 'rgba(246, 134, 42, 1)',
        fontSize: '30px',
        padding: '8px',
        // width: '200px'
        borderRadius: '10px',
        marginTop: '30px',
        marginBottom: '5px',
    },
    radioContainer: {
        // backgroundColor: 'rgba(225, 225, 225, 0.8)',
        // borderRadius: '5px',
        // width: '100px',
        // padding: '10px',
        // margin: 'auto'
    },
    radioLabel: {
        color: 'white'
    },
    fieldContainer: {
        // marginLeft: 'auto',
        // display: 'flex'
        // float: 'left',
        maxWidth: '350px',
        // width: '100%',
        marginBottom: '20px',
        // minWidth: '200px'
    },
    longFieldContainer: {
        width: '100%'
    },
    sectionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    leftContainer: {
        // paddingRight: '3vw'
        marginRight: '15px',
        '@media (max-width:1004px)': {
            margin: '0',
            width: '100%'
        },
    },
    rightContainer: {
        // paddingLeft: '3vw'
        marginLeft: '15px',
        '@media (max-width:1004px)': {
            margin: '0',
            width: '100%'
        },
    },
    fieldLabel: {
        fontSize: '14px',
        color: 'white',
        // backgroundColor: 'rgba(246, 134, 42, 1)',
        // paddingLeft: '8px',
        // marginLeft: '10px',
        borderRadius: '5px',
        marginBottom: '8px',
        marginTop: '8px',
        maxWidth: '500px',
        // width: 'fit'
    },
    inputField: {
        backgroundColor: 'rgba(214, 214, 214, 0.8)',
        borderRadius: '5px',
        // margin: '1vw',
        // '@media (max-width:550px)': {
        //     margin: '3vw',
        // },
        // '@media (max-width:350px)': {
        //     margin: '3vw',
        // },
    },
    addBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        marginBottom: '0px',
        '@media (max-width:450px)': {
            marginTop: '10vw',
        },
    },
    navBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        // marginBottom: '8vh',
        marginBottom: '40px',
        '@media (max-width:450px)': {
            // marginTop: '10vw',
        },
    },
    longInputField: {
        color: 'white',
        borderColor: 'white',
        backgroundColor: 'rgba(225, 225, 225, 0.8)',
        borderRadius: '5px',
        width: '100%',
        flex: 1,
        // margin: '1vw',
        // '@media (max-width:550px)': {
        //     width: '75%',
        //     marginTop: '3vw',
        // },
        // maxWidth: '800px'
        // margin: '10px',
        // backgroundColor: 'white'
    },
}))


const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
      '&.Mui-disabled': {
        color: 'gray',
        borderColor: 'gray',
        backgroundColor: 'rgba(255, 255, 255, 0.15)'
      },
    },
}))(Button);

const OrangeRadio = withStyles({
    root: {
      color: 'white',
      '&$checked': {
        color: 'rgba(246, 134, 42, 1)',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const SpecialTextField = withStyles({
    root: {
        // '& label': {
        //     color: 'black',
        //     backgroundColor: 'white',
        //     padding: '3px',
        //     borderRadius: '4px',
        // },
        // '& label.Mui-focused': {
        //     color: 'black',
        //     backgroundColor: 'orange',
        //     padding: '3px',
        //     borderRadius: '4px',
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

const LongTextField = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '3px',
            borderRadius: '4px',
            // display: 'none'
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

export default InputTable
