import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const NavButtons = ({ prevStep, nextStep, disabled }) => {
    const classes = useStyles();

    const Previous = e => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        prevStep();
    }
    const Continue = e => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        nextStep();
    }

    return (
        <div>
            <SpecialButton
                className={classes.navBtn}
                variant="outlined"
                onClick={ Previous }
            >
                Back
            </SpecialButton>
            <SpecialButton
                className={classes.navBtn}
                variant="outlined"
                onClick={ Continue }
                disabled={disabled}
            >
                Next
            </SpecialButton>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    navBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        // marginBottom: '8vh',
        marginBottom: '40px',
        '@media (max-width:450px)': {
            // marginTop: '10vw',
        },
    },
}))

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
      '&.Mui-disabled': {
        color: 'gray',
        borderColor: 'gray',
        backgroundColor: 'rgba(255, 255, 255, 0.15)'
      }
    },
}))(Button);

export default NavButtons
