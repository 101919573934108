import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const LayoutNumber = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.number}>
                Layout #{props.layoutNumber}
            </Typography>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    number: {
        color: 'white',
        backgroundColor: 'rgba(246, 134, 42, 1)',
        borderRadius: '15px 0px 0px 0px',
        padding: '2vw 2vw 2vw 2.5vw',
        fontSize: '2.5vw',
        fontWeight: '500',
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        '@media (min-width:950px)': {
            fontSize: '2vw',
            padding: '2vw 2vw 2vw 2.5vw',
            borderRadius: '20px 0px 0px 0px',
        },
        '@media (max-width:590px)': {
            fontSize: '4.5vw',
            padding: '2.5vw 2.5vw 2.5vw 3vw',
            borderRadius: '10px 0px 0px 0px',
        },
    }
}))

export default LayoutNumber
