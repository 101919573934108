import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const EditableCell = ({
    value: initialValue,
    row,
    column,
    updateMyData,
    updateTable
}) => {
    const classes = useStyles();
    const [value, setValue] = useState(initialValue);
    
    const onChange = e => {
        setValue(e.target.value)
    }
    
    const onBlur = () => {
        // console.log('EDITABLE CELL:', value)
        // console.log('EDITABLE CELL:', row.index)
        // console.log('EDITABLE CELL:', column.id)
        updateTable(value, row.index, column.id)
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <input
            className={classes.inputCell}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    inputCell: {
        width: '80px',
        height: '22px',
        backgroundColor: 'rgba(255, 255, 255, 0.80)',
        borderRadius: '5px',
        borderColor: 'orange'
    }
}))

export default EditableCell
