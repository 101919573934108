import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';

const Review = ({ prevStep, nextStep, values }) => {
    const classes = useStyles();

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }
    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Everything look right?"}/>
            <div className={classes.infoContainer}>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Name: </span>{values.name}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Email: </span>{values.email}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Company: </span>{values.company}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Project Name: </span>{values.projectTitle}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>General Contractor: </span>{values.gc}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Architect: </span>{values.architect}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Site Address: </span>{values.address}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}># of floors: </span>{values.numberOfFloors}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Average floor size (sq. ft): </span>{values.approxFloorSize}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}># of unique layouts: </span>{values.numberOfUniqueFloors}
                </Typography>
                <div className={classes.tableContainer}>
                    <table className={classes.table} cellSpacing="0">
                        <thead>
                            <tr>
                                <th className={classes.th}>layout name</th>
                                <th className={classes.th}># of floors</th>

                                <th className={classes.th}>framing layout (LF)</th>
                                <th className={classes.th}>soffit framing/finish (LF)</th>
                                <th className={classes.th}>include drywall finish?</th>
                                <th className={classes.th}># of doors</th>
                                <th className={classes.th}># of hanger points</th>
                                <th className={classes.th}>ductwork (LF)</th>
                                <th className={classes.th}>piping (LF)</th>
                                <th className={classes.th}># of penetrations</th>
                                <th className={classes.thNotes}>enhanced layout</th>
                                <th className={classes.thNotes}>schedule notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            { values.rowData.map(layout => (
                                <tr>
                                    <td className={classes.td}>{layout.layoutName}</td>
                                    <td className={classes.td}>{layout.howManyFloors}</td>
                                    <td className={classes.td}>{layout.lfFraming}</td>
                                    <td className={classes.td}>{layout.lfFinish}</td>
                                    <td className={classes.td}>{layout.includeFinLay}</td>
                                    <td className={classes.td}>{layout.doorCount}</td>
                                    <td className={classes.td}>{layout.pointCount}</td>
                                    <td className={classes.td}>{layout.lfDuctwork}</td>
                                    <td className={classes.td}>{layout.lfPiping}</td>
                                    <td className={classes.td}>{layout.penetrationCount}</td>
                                    <td className={classes.tdNotes}>{layout.enhancedLayout}</td>
                                    <td className={classes.tdNotes}>{layout.scheduleNotes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Architectural file: </span><a href={values.drawing} target="_blank" rel="noreferrer" className={classes.questionText}>{values.drawing}</a>
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>CAD Help: </span>{values.cadHelp}
                </Typography>
                <Typography className={classes.questionText}>
                    <span className={classes.bold}>Additional details: </span>{values.extraNotes}
                </Typography>
            </div>
                <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Previous }>
                    Back
                </SpecialButton>
                <SpecialButton className={classes.navBtn} variant="outlined" onClick={ Continue }>
                    Submit
                </SpecialButton>
                <ProgressBar step={6} completed={100} />
                <Footer />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto',
        '@media (max-width:400px)': {
            width: '80%',
        },
    },
    infoContainer: {
        marginTop: '3vw',
        '@media (max-width:500px)': {
            marginTop: '5vw',
        },
    },
    questionText: {
        color: 'white',
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '5px',
        overflowWrap: 'break-word',
        '@media (max-width:400px)': {
            fontSize: '4.5vw',
        },
    },
    bold: {
        fontFamily: 'Roboto',
        fontWeight: '700',
        color: 'orange'
    },
    tableContainer: {
        width: '100%',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    table: {
        border: '3px solid white',
        fontFamily: 'Roboto',
        fontWeight: '300',
        cellSpacing: 0,
        width: '100%',
        // wordBreak: 'break-word'
        // tableLayout: 'fixed'
    },
    th: {
        border: '1px solid white',
        fontWeight: '700',
        color: 'white',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    thNotes: {
        border: '1px solid white',
        fontWeight: '700',
        color: 'white',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        minWidth: '200px'
    },
    td: {
        border: '1px solid white',
        fontWeight: '400',
        color: 'white',
        padding: '10px',
    },
    tdNotes: {
        border: '1px solid white',
        fontWeight: '400',
        color: 'white',
        padding: '10px',
        minWidth: '200px'
    },
    navBtn: {
        color: 'white',
        borderColor: 'white',
        margin: '2px',
        marginTop: '3vw',
        // marginBottom: '8vh',
        marginBottom: '40px',
        '@media (max-width:450px)': {
            // marginTop: '10vw',
        },
    }
}))

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
    },
}))(Button);

export default Review
