import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomTextField from './components/CustomTextField';
import NavButtons from './components/NavButtons';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';

const BasicInfo = ({ prevStep, nextStep, handleChange, values }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (values.projectTitle !== '' && values.gc !== '' && values.architect !== '' && values.address !== '' && values.numberOfFloors > 0 && values.approxFloorSize > 0) {
            setDisabled(false)
        }
        if (values.projectTitle === '' || values.gc === '' || values.architect === '' || values.address === '' || values.numberOfFloors <= 0 || values.approxFloorSize <= 0) {
            setDisabled(true)
        }
    }, [values])

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Tell us about your project"}/>
            <form>
                <CustomTextField
                    className={classes.inputField}
                    type="text"
                    label="Project Name"
                    onChange={handleChange('projectTitle')}
                    defaultValue={values.projectTitle}
                />
                <CustomTextField
                    className={classes.inputField}
                    type="text"
                    label="GC"
                    onChange={handleChange('gc')}
                    defaultValue={values.gc}
                />
                <CustomTextField
                    className={classes.inputField}
                    type="text"
                    label="Architect"
                    onChange={handleChange('architect')}
                    defaultValue={values.architect}
                />
                <div className={classes.spacer}></div>
                <CustomTextField
                    className={classes.inputField}
                    type="text"
                    label="Address"
                    multiline={true}
                    onChange={handleChange('address')}
                    defaultValue={values.address}
                />
                <br />
                <CustomTextField
                    className={classes.inputField}
                    type="number"
                    label="Number of floors"
                    onChange={handleChange('numberOfFloors')}
                    defaultValue={values.numberOfFloors}
                />
                <CustomTextField
                    className={classes.inputField}
                    type="number"
                    label="Average sqft per floor"
                    onChange={handleChange('approxFloorSize')}
                    defaultValue={values.approxFloorSize}
                />
                <br />
                {/* <SpecialTextField
                    className={classes.inputField}
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={handleChange('extraNotes')}
                    defaultValue={values.extraNotes}
                /> */}
                <NavButtons prevStep={prevStep} nextStep={nextStep} disabled={disabled}/>
                <ProgressBar step={2} completed={33} />
            </form>
            <Footer />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    spacer: {
        height: '20px',
        '@media (max-width:590px)': {
            height: '0px',
        },
    },
    inputField: {
        color: 'white',
        borderColor: 'white',
        backgroundColor: 'rgba(225, 225, 225, 0.8)',
        borderRadius: '5px',
        width: '80%',
        flex: 1,
    },
}))

const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'dark gray',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

export default BasicInfo
