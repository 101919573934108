import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';

import API from '../utils/API';

const Loading = ({ nextStep, values }) => {
    const classes = useStyles();
    const [error, setError] = useState(false);

    useEffect(() => {
        API.createBid(values)
        .then(function(response) {
            nextStep();
        })
        .catch(function(err) {
            // console.log('frontend error', err)
            setError(true)

            API.sendErrorEmail(values)
            .then(function(response) {
                console.log('error email has been sent')
            })

        });
    }, [])

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            { error != true ? (
                <>
                    <CustomHeader className={classes.header} content={"One moment please..."}/>
                    <CircularProgress color="secondary"/>
                </>
            ) : (
                <>
                    <CustomHeader className={classes.header} content={"Something went wrong.."}/>
                    <Typography className={classes.description} variant="h2">
                        We apologize for the inconvenience. Please contact us at <a href="mailto:sales@dustyrobotics.com" className={classes.emailLink}>sales@dustyrobotics.com</a> so we can solve this issue as soon as possible.
                    </Typography>
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
    description: {
        color: 'white',
        fontSize: '18px',
        marginTop: '20px',
        marginBottom: '20px',
        fontWeight: '400'
    },
    emailLink: {
        color: 'orange'
    }
}))

export default Loading
