import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const CustomTextField = ({ label, onChange, defaultValue, type, multiline }) => {
    const classes = useStyles();

    return (
        <SpecialTextField
            InputLabelProps={{ shrink: true }}
            onWheel={(e) => e.target.blur()}
            className={classes.textField}
            type={type}
            multiline={multiline}
            // fullWidth
            inputProps={{ min: 0 }}
            variant="outlined"
            label={label}
            onChange={onChange}
            defaultValue={defaultValue}
        />
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    textField: {
        backgroundColor: 'rgba(214, 214, 214, 0.8)',
        borderRadius: '5px',
        margin: '1vw',
        '@media (max-width:550px)': {
            margin: '3vw',
        },
        '@media (max-width:350px)': {
            margin: '3vw',
        },
    },
}))

const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '3px',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

export default CustomTextField
