import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomTextField from './components/CustomTextField';
import NavButtons from './components/NavButtons';

const Address = ({ prevStep, nextStep, handleChange, values }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (values.streetAddress !== '' && values.city !== '' && values.state !== '') {
            setDisabled(false)
        }
        if (values.streetAddress === '' || values.city === '' || values.state === '') {
            setDisabled(true)
        }
    }, [values])

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Where is the site located?"}/>
            <form>
                <CustomTextField
                    className={classes.inputField}
                    label="Street Address"
                    onChange={handleChange('streetAddress')}
                    defaultValue={values.streetAddress}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="City"
                    onChange={handleChange('city')}
                    defaultValue={values.city}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="State"
                    onChange={handleChange('state')}
                    defaultValue={values.state}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="Zipcode"
                    onChange={handleChange('zipcode')}
                    defaultValue={values.zipcode}
                />
                <NavButtons prevStep={prevStep} nextStep={nextStep} disabled={disabled}/>
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
}))

export default Address
