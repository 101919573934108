import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LogoStamp from './components/LogoStamp';
import CustomHeader from './components/CustomHeader';
import CustomTextField from './components/CustomTextField';
import NavButtons from './components/NavButtons';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';

const Contact = ({ prevStep, nextStep, handleChange, values }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (values.name !== '' && values.company !== '' && values.email !== '' && values.phone !== '') {
            setDisabled(false)
        }
        if (values.name === '' || values.company === '' || values.email === '' || values.phone === '') {
            setDisabled(true)
        }
    }, [values])

    return (
        <div className={classes.mainContainer}>
            <LogoStamp />
            <CustomHeader className={classes.header} content={"Let's get to know you"}/>
            <form>
                <CustomTextField
                    className={classes.inputField}
                    label="Full Name"
                    onChange={handleChange('name')}
                    defaultValue={values.name}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="Company"
                    onChange={handleChange('company')}
                    defaultValue={values.company}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="Email Address"
                    onChange={handleChange('email')}
                    defaultValue={values.email}
                />
                <CustomTextField
                    className={classes.inputField}
                    label="Phone Number"
                    onChange={handleChange('phone')}
                    defaultValue={values.phone}
                />
                <NavButtons prevStep={prevStep} nextStep={nextStep} disabled={disabled}/>
                <ProgressBar step={1} completed={17} />
            </form>
            <Footer />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '70%',
        margin: 'auto'
    },
}))

export default Contact
